import React from "react";

interface Props {
    fill: string;
    className?: string,
}

export const StraightCover: React.FC<Props> = ({ fill, className = "" }) => {
    return (
        <svg width="1920" height="44" viewBox="0 0 1920 44" fill={fill} className={`straight-cover ${className}`}>
            <defs>
                <linearGradient id="orangeFooterGradient" gradientTransform="rotate(0)">
                    <stop offset="0" stop-color="#ff7200" />
                    <stop offset="100%" stop-color="#ffb734" />
                </linearGradient>
            </defs>
            <path d="M809.4,28.9c-11.8,0.5-21.2,3.8-21,7.4c0.2,3.6,9.8,6.1,21.6,5.6c11.8-0.5,21.2-3.8,21-7.4
            C830.9,30.9,821.2,28.4,809.4,28.9z"/>
            <path d="M234.2,26.9c-3,0.2-5.4,1.2-5.4,2.3c0.1,1,2.6,1.7,5.6,1.5c3-0.2,5.4-1.2,5.4-2.3
		    C239.8,27.4,237.2,26.7,234.2,26.9z"/>
            <path d="M1065.9,35.5c-6.3,0.2-11.4,1.9-11.3,3.7c0.1,1.8,5.3,3.1,11.6,2.8c6.3-0.2,11.4-1.9,11.3-3.7
		    C1077.4,36.5,1072.2,35.3,1065.9,35.5z"/>
            <path d="M0,0v7.8v19.9v5.7c19.4,1.7,44.6,2.3,72.7,1.6c2.6,5,11.7,8.4,22.3,7.9c10.6-0.5,19.4-4.7,21.5-9.9
            c19.1-1.3,36.9-3.1,52.5-5.1v-0.2c0,0,59.4-5,133.1-10.4c1.4-3.5,24.2-7.6,52.3-9.5c27.2-1.8,49.8-0.7,52.9,2.3
            c77.2-4.8,148.2-7.9,168-4.6c17.3,2.8-29.2,6.9-96.2,10.9c-6.6-3.4-19.6-5.1-34.5-4.2c-13,0.8-24.3,3.6-31,7
            c-63.2,1.8-150.4,4.9-165.8,8.4c-12.5,2.9,73.8,2.8,161.4,2.1c5.2,4.4,19.9,6.9,36.9,5.7c12.2-0.8,22.9-3.2,29.7-6.4
            c57.4-0.7,102.9-1.4,102.9-1.4h11.5c2.7,0.5,6.7,1,12.1,1.6c9.6,1,19.6,1.7,29.9,2.1c-0.1,0.2-0.1,0.5-0.1,0.7
            c0,3.7,10,6.7,22.4,6.7s22.4-3,22.4-6.7c0,0,0,0,0,0c28.5-0.3,56.6-1.7,80.2-3.4c-0.6,0.6-0.9,1.2-0.9,1.8c0.1,2,3.5,3.6,7.5,3.4
            c4.1-0.2,7.3-2,7.2-4c0-0.8-0.6-1.6-1.5-2.1c30.2-2.4,50.7-5.1,50.7-5.2c13.1-1.2,27.7-2.5,42.4-3.6c4.8-2.7,18-5.1,33.6-5.7
            c14.6-0.6,27.2,0.4,32.9,2.4c6.5,0,12.2,0.1,16.9,0.4c12.8,0.9,3.9,2.4-15.2,4c-0.9,0.6-2.2,1.2-3.9,1.7c-1.1,0.4-2.5,0.8-3.9,1.1
            c0,0-0.1,0-0.1,0c-0.4,0.1-0.9,0.2-1.4,0.3c-0.1,0-0.2,0.1-0.4,0.1c-0.5,0.1-0.9,0.2-1.4,0.3c-0.1,0-0.2,0-0.3,0.1
            c-1.4,0.3-3,0.5-4.6,0.8c-0.3,0-0.6,0.1-0.9,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0.1-1.1,0.1
            c-0.5,0.1-1.1,0.1-1.6,0.2c-0.3,0-0.6,0.1-0.8,0.1c-0.8,0.1-1.6,0.2-2.3,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.6,0-1.2,0.1-1.7,0.1
            c-0.3,0-0.6,0-1,0.1c-0.6,0-1.2,0.1-1.8,0.1c-0.3,0-0.6,0-0.9,0.1c-0.9,0.1-1.8,0.1-2.7,0.1c-13.1,0.6-24.5-0.2-30.9-1.8
            c-1.8,0.1-3.6,0.2-5.5,0.3c-0.8,0-1.7,0.1-2.5,0.1c-0.9,0.1-1.8,0.1-2.7,0.2c-1.3,0.1-2.5,0.1-3.8,0.2c-0.4,0-0.9,0-1.3,0.1
            c-1.5,0.1-3,0.2-4.5,0.2c-0.8,0-1.7,0.1-2.5,0.1c-1.1,0.5-1.1,1.1,0.5,1.8c7.1,3.2,56.8,10.2,109.1,2.6c28.9,1.4,78.8,1.2,135.4-1
            c4-0.2,8-0.3,12-0.5c1.3,4.5,8.1,7.7,16.3,7.3c8.3-0.3,15-4.2,15.7-8.9c33.5-1.8,62.6-4.1,83.3-6.5c55.5-3.1,122.5-6.2,181-7.1
            c4.5-2.2,16.4-3.8,30.3-3.8c13.8,0,25.6,1.5,30.2,3.7c18.4,0.4,34.5,1.1,47.5,2.4c6.8,0.7-16.6,2-55.1,3.7
            c-5.8,1-13.8,1.6-22.6,1.6c-3.5,0-6.9-0.1-10.1-0.3c-12,0.5-24.8,1-38,1.5c-5.6-2.7-16.3-4.2-28.5-3.7c-12,0.5-22.4,2.8-27.8,5.8
            c-7.8,0.3-15.7,0.5-23.5,0.8c-18.4,0.4-42.1,1.1-63.9,2.2c-5.5,0.2-11,0.4-16.3,0.5h5.5c-21.9,1.2-40.4,2.7-47.4,4.7
            c-10.8,3.1,64.1,1.5,142.9-0.9c4.1,3.7,16.6,6,31.2,5.5c14.2-0.6,26.2-3.7,30.4-7.5c16.9-0.6,32.9-1.2,47.1-1.8h0.2
            c8.9,3.1,53.1,5.4,106.3,5.4c54.9,0,100.2-2.5,107-5.7c9.1-1.1,41.2-4.7,80.3-8.1c1.8-0.2,3.5-0.3,5.3-0.4c0.3,0,0.7-0.1,1-0.1
            c1.8-0.1,3.5-0.3,5.3-0.4c0.3,0,0.6-0.1,1-0.1c1.7-0.1,3.4-0.3,5.2-0.4c0.2,0,0.3,0,0.5,0c1.8-0.1,3.7-0.3,5.5-0.4
            c0.4,0,0.8-0.1,1.2-0.1c1.8-0.1,3.7-0.3,5.5-0.4c0.2,0,0.4,0,0.7,0c1.8-0.1,3.5-0.2,5.3-0.4c0.3,0,0.6,0,0.9-0.1
            c1.9-0.1,3.7-0.3,5.6-0.4c0.4,0,0.7,0,1.1-0.1c1.9-0.1,3.8-0.2,5.8-0.4c0,0,0.1,0,0.1,0c1.9-0.1,3.8-0.2,5.7-0.3
            c0.4,0,0.8,0,1.1-0.1c1.9-0.1,3.7-0.2,5.6-0.3c0.3,0,0.6,0,1-0.1c1.8-0.1,3.5-0.2,5.3-0.3c0.2,0,0.4,0,0.6,0
            c1.9-0.1,3.7-0.2,5.6-0.3c0.4,0,0.8,0,1.2-0.1c1.9-0.1,3.7-0.2,5.6-0.2c0.2,0,0.4,0,0.6,0c1.7-0.1,3.5-0.1,5.2-0.2
            c0.3,0,0.6,0,0.9,0c1.8-0.1,3.6-0.1,5.4-0.2c0.4,0,0.7,0,1.1,0c1.8-0.1,3.7-0.1,5.5-0.1c3.7-3.9,11-6.8,19.6-7.1
            c9.9-0.4,18.5,2.7,21.8,7.4c7.4,0.3,14.2,0.9,20.2,1.6c20,2.4,7.6,4.8-20.3,6.9c-0.2,0.4-0.5,0.7-0.8,1c-0.1,0.1-0.2,0.2-0.3,0.3
            c-0.3,0.3-0.6,0.6-0.9,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.7,0.6-1.1,0.9c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.6,0.4-0.9,0.6
            c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.2,0.1-0.4,0.2-0.7,0.3c-1.7,0.8-3.5,1.4-5.5,1.9c-0.6,0.1-1.2,0.3-1.8,0.4
            c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-1,0.2-1.5,0.3c-0.2,0-0.3,0-0.5,0.1c-0.5,0.1-1,0.1-1.5,0.2c-0.2,0-0.3,0-0.5,0.1
            c-0.7,0.1-1.3,0.1-2,0.1c-8.9,0.4-16.7-2.1-20.6-6c0,0,0,0-0.1,0c-2.6-2.7-16.6-4.5-33.5-4.1c-5.3,0.1-10.6,0.3-15.8,0.9
            c-5,0.6-10.3,1.3-15,3.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.8,0.3-1.6,0.8-2,1.5c-0.2,0.3-0.2,0.7-0.5,0.9c-0.2,0.1-0.4,0.1-0.7,0.1
            c-9,0.4-18,0.7-27,1c-0.2,0-0.3,0-0.5,0c-1.4-2.2-5.4-3.8-10-3.8c-5.1,0-9.4,1.9-10.4,4.5c-7.7,0.2-15.1,0.5-22.2,0.7
            c-4.9,1.3,5.1,2,22.5,2.3c1.4,2.2,5.4,3.8,10,3.8c4.5,0,8.3-1.5,9.8-3.6c19.5,0.1,25.4,2.8,47.3,2.7c32.4,0.7,39.6-0.5,51.2,0.4
            c38.3,2.8,72.7,2.7,95.3,0.2V0H0z M150.6,21.6c-8.1,0.4-14.9-2.6-15.1-6.7c-0.2-4,6.3-7.6,14.4-8c8.1-0.4,14.9,2.6,15.1,6.7
            C165.2,17.7,158.7,21.3,150.6,21.6z M970.9,17.4c-4.2,0.2-7.6-0.5-7.6-1.4c0-1,3.3-1.9,7.5-2.1c4.2-0.2,7.6,0.5,7.6,1.4
            C978.4,16.3,975.1,17.2,970.9,17.4z M1605.3,24.7c-6.2,0.3-11.3-1.2-11.4-3.2c-0.1-2,4.9-3.9,11.1-4.2c6.2-0.3,11.3,1.2,11.4,3.2
            C1616.5,22.6,1611.5,24.4,1605.3,24.7z"/>
        </svg>
    )
}